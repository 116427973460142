import navigate from "@utils/navigate";
import {
  useCallback,
  useMemo,
  useRef,
  useState,
  type PropsWithChildren,
} from "react";
import { $isRoadmapOpen } from "stores/roadmap";
import Button from "./ui/Button";
import Input from "./ui/Input";

interface Props extends PropsWithChildren {}

export default function WelcomeForm({ children }: Props) {
  const emailInputRef = useRef<HTMLInputElement>(null);
  const [email, setEmail] = useState("");

  const valid = useMemo(
    () => email.length > 0 && emailInputRef.current?.validity.valid,
    [email]
  );

  const onSubmit = useCallback(() => {
    if (!valid) {
      return;
    }

    navigate(`/onboarding/66532e9e097a0f8be2f74363?email=${email}`);
  }, [valid, email]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        return onSubmit();
      }}>
      <h1 className="text-3xl/tight sm:text-5xl sm:leading-tight font-mono font-semibold text-brand-800 tracking-tighty">
        Slash your Building Code Compliance Review time by 90%
      </h1>
      <p className="text-lg lg:text-xl leading-normal mt-4 mb-2 lg:my-6 text-gray-600 max-w-xl font-normal">
        Join us in shaping the first AI-powered Building Code Compliance
        Co-pilot. Transforming floor plan reviews from tedious to instantaneous.{" "}
        <Button
          type="button"
          onClick={() => $isRoadmapOpen.set(true)}
          style="muted"
          className="underline underline-offset-4 font-normal">
          View roadmap
        </Button>
      </p>
      <div className="pt-6 pb-1.5 grid sm:grid-cols-2 place-items-start gap-x-4 gap-y-6">
        <div className="w-full flex flex-col gap-1.5">
          <Input
            ref={emailInputRef}
            placeholder="Enter your email"
            block
            id="onboarding-email"
            name="email"
            type="email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <Button
          className="max-sm:w-full"
          disabled={!valid}
          type="button"
          onClick={onSubmit}>
          Get early access
        </Button>
      </div>
      <div className="text-sm text-gray-600">
        We won't spam your inbox, we’re cool people.
      </div>
      {children}
    </form>
  );
}
